import React, { useState } from "react";

const CalorieTracker = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setImage(URL.createObjectURL(file));
    
    const apiKey = process.env.NEXT_PUBLIC_OPENAI_API_KEY;
    if (!apiKey) {
      console.error("API key is missing");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await fetch("https://api.openai.com/v1/images/gpt-4o/analyze", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to analyze image: ${response.statusText}`);
      }
      
      const data = await response.json();
      if (!data.nutrients) {
        throw new Error("Invalid response format from API");
      }
      setAnalysis(data);
    } catch (error) {
      console.error("Error analyzing image:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <button>
        <label>
          Take a picture of your food
          <input type="file" accept="image/*" capture="environment" className="hidden" onChange={handleImageUpload} />
        </label>
      </button>
      
      <button>
        <label>
          Upload an image from device
          <input type="file" accept="image/*" className="hidden" onChange={handleImageUpload} />
        </label>
      </button>

      {loading && <p>Loading...</p>}

      {image && (
        <div>
          <img src={image} alt="Uploaded food" style={{ width: "100%" }} />
        </div>
      )}

      {analysis && (
        <div className="text-center">
          <p>{analysis.description}</p>
          <p>Calories: {analysis.nutrients.calories} kcal</p>
          <p>Protein: {analysis.nutrients.protein} g</p>
          <p>Fat: {analysis.nutrients.fat} g</p>
          <p>Carbs: {analysis.nutrients.carbs} g</p>
        </div>
      )}
    </div>
  );
};

export default CalorieTracker;
